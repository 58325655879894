import React, { useRef, useState, useEffect } from "react";
import Row from "react-bootstrap/Row";

import "./AboutMe.scss";
import { iconNames, aboutMeText } from "../../generalObjects/AboutMe/about";
import SkillIcon from "./components/SkillIcon";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { Fade } from "react-awesome-reveal";

function AboutMe() {
  const [showMoreDesc, setshowMoreDesc] = useState(false);
  const [showMoreSkills, setshowMoreSkills] = useState(false);
  const [heightExceededDesc, setheightExceededDesc] = useState(false);
  const [heightExceededSkills, setheightExceededSkills] = useState(true);
  const [shownSkills, setShownSkills] = useState([]);
  const elementRef = useRef(null);

  useEffect(() => {
    var height = elementRef.current.clientHeight;
    if (height > 300) setheightExceededDesc(true);
    setSkillsToShow(elementRef.current.clientHeight);
  }, []); //empty dependency array so it only runs once at render

  useEffect(() => {
    if (showMoreSkills) {
      setShownSkills(iconNames);
    } else {
      setSkillsToShow();
    }
  }, [showMoreSkills]);

  const setSkillsToShow = () => {
    if (window.innerWidth >= 992) {
      setheightExceededSkills(false);
      setShownSkills(iconNames);
    } else if (window.innerWidth >= 768) {
      setShownSkills(iconNames.slice(0, 6));
    } else {
      setShownSkills(iconNames.slice(0, 4));
    }
  };

  const showMoreDescArrow = {
    transform: showMoreDesc ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
  };

  const showMoreSkillsArrow = {
    transform: showMoreSkills ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
  };

  return (
    <div id={"About"} className="AboutMe_container">
      <div className="uncenter_row section_heading">
        <h2 className="primary_title_text">About Me</h2>
        <hr className="line_seperator" />
      </div>
      <Fade duration={2500} triggerOnce={true} cascade={true}>
        <div>
          <div
            className="about_me round_border"
            ref={elementRef}
            style={heightExceededDesc ? { paddingBottom: "0px" } : {}}
          >
            <div>
              <p
                className={`${
                  heightExceededDesc && !showMoreDesc && "text_ellipsis"
                }`}
              >
                {aboutMeText}
              </p>
            </div>
            {heightExceededDesc && (
              <div className="show_more_btn">
                <div style={showMoreDescArrow}>
                  <MdKeyboardArrowDown
                    onClick={() => setshowMoreDesc(!showMoreDesc)}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            id={"MySkills"}
            className={`skills_container ${showMoreSkills ? "expanded" : ""}`}
          >
            <div className="uncenter_row section_heading">
              <h2 className="primary_title_text">My Skills</h2>
              <hr className="line_seperator" />
            </div>
            <div
              className="skills round_border"
              style={heightExceededSkills ? { paddingBottom: "0px" } : {}}
            >
              <Row style={{ alignItems: "center" }}>
                {shownSkills.map((icon) => {
                  return <SkillIcon icon={icon} />;
                })}
              </Row>
              {heightExceededSkills && (
                <div className="show_more_btn">
                  <div style={showMoreSkillsArrow}>
                    <MdKeyboardArrowDown
                      onClick={() => setshowMoreSkills(!showMoreSkills)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default AboutMe;
