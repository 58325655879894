import React from "react";
import "./Contact.scss";
import { socialLinks } from "../../generalObjects/Socials/socialLinks";

function index() {
  return (
    <>
      <div className="Contact_container" id="Contact">
        <div className="uncenter_row ">
          <h2 className="primary_title_text">Contact Me</h2>
        </div>
        <div className="center_row">
          {socialLinks.map((social) => {
            return (
              <a href={social.link} target="_blank" className="contact_icons">
                {social.icon}
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default index;
