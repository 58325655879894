import React from "react";
import "./JobDescription.scss";
import { MdArrowForwardIos } from "react-icons/md";

function JobDescription({ job }) {
  return (
    <div className="JobDescription_container">
      <div className="job_details round_border">
        <div className="job_title">
          <div className="uncenter_row">
            <h2 className="job_position secondary_text">{job.jobPosition}</h2>
            <h2 className="secondary_text"> @ {job.companyName}</h2>
          </div>
          <h4 className="small_text">{job.workPeriod}</h4>
        </div>
        {job.resumePoints.map((point) => {
          return (
            <div className="point_container">
              <MdArrowForwardIos className="point_arrow" />
              <p>{point}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default JobDescription;
