export const aboutMeText =
  "Hi, my name is Zain and I am currently in my 4A term studying Software Engineering at the University of Waterloo. I got into software development many years ago and I've been hooked on it ever since. I've worked at various companies and collaborated with talented people to create software applications. I thoroughly enjoy coding and learning new things as I move forward. My skill's range from web development with React to programming with C++ and Python.";

export const iconNames = [
  "React",
  "Django",
  "Javascript",
  "Python",
  "Java",
  "HTML",
  "SCSS",
  "MySQL",
  "GCP",
  "AWS",
  "Express",
  "Springboot",
];
