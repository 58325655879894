import React from "react";
import { FileEarmarkText } from "react-bootstrap-icons";

import { navBarData } from "../../generalObjects/NavBar/navBar";

import "./NavBar.scss";

function NavBar() {
  const { logo, resume } = navBarData;
  return (
    <div className="NavBar_container">
      <div className="logo_container">
        <h2 className="logo">
          <a className="nav_element" href="#Home">
            {logo}
          </a>
        </h2>
      </div>
      <div className="nav_elements_container">
        <a className="nav_element" href="#About">
          About
        </a>
        <a className="nav_element" href="#MySkills">
          Skills
        </a>
        <a className="nav_element" href="#Work">
          Work
        </a>
        <a className="nav_element" href="#Projects">
          Projects
        </a>
        <a className="nav_element" href="#Contact">
          Contact
        </a>
        <div className="btn_container">
          <a className="resume_btn" href={resume} target="_blank">
            <button className="button blue_highlight">Resume</button>
            <FileEarmarkText className="resume_icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
