import React from "react";
import Col from "react-bootstrap/Col";

import "./SkillIcon.scss";

function SkillIcon({ icon }) {
  return (
    <Col className="icon_col" lg={2} md={2} sm={3} xs={6} xxs={6}>
      <div className="icon_box">
        <img className="skill_icons" src={`images/${icon}.png`} />
        <p>{icon}</p>
      </div>
    </Col>
  );
}

export default SkillIcon;
