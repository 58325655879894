export const featuredProjects = [
  {
    id: 1,
    title: "Microcomputers",
    image: "images/Microcomputers.png",
    description:
      "Built e-commerce application for computer electronics allowing users to create/login an account, add items to cart, view cart from previous session, checking out, searching for items, etc. within a modern UI ",
    technologies: [
      "React",
      "Django",
      "Python",
      "Redux",
      "Javascript",
      "MySQL",
      "Bootstrap",
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/zainafzal0/MicroComputers",
    },
  },
  {
    id: 2,
    title: "Pass Guard",
    image: "images/passguard.png",
    description:
      "Created a full stack password manager application which includes user registration, login, and authentication. " +
      "The User can store all their passwords securely as the passwords get encrypted when stored in database",
    technologies: [
      "React",
      "Node.js",
      "Express",
      "JavaScript",
      "MySQL",
      "Redux",
      "Bootstrap",
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/zainafzal0/PassGuard",
    },
  },
  {
    id: 3,
    title: "OmniFlight",
    image: "images/omniHome.png",
    description:
      "Developed a flight tracker app with direct flights from more than 1000 airlines on an interactive flight map. " +
      "You can search, find, compare, and save any flight path or airline route",
    technologies: [
      "React",
      "Redux",
      "Node.js",
      "Express",
      "JavaScript",
      "MySQL",
      "Docker",
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/zainafzal0/OmniFlight",
    },
  },
];

export const projects = [
  // {
  //   title: "Hero Index",
  //   image: "images/heroIndex1.png",
  //   description:
  //     "Created a React app where the user can search up any hero and retrieve information on them like: biography, appearance, powerstats, etc. This was done by using the Superhero API" +
  //     "",
  //   technologies: [
  //     "React ",
  //     "Bootstrap ",
  //     "Javascript ",
  //     "HTML ",
  //     "CSS ",
  //     "Superhero API ",
  //   ],
  //   links: {
  //     projectLink: undefined,
  //     github: "https://github.com/zainafzal0/hero-index",
  //   },
  // },
  {
    title: "Street Fighter",
    image: "images/streetFighter1.png",
    description:
      "Cloned the nostalgic Street Fighter game using Java and Java AWT framework. Created a main menu, map selection screen where user select various maps to fight one on one",
    technologies: ["Java ", "Java AWT ", "OOP "],
    links: {
      projectLink: undefined,
      github: "https://github.com/zainafzal0/Street-Brawler",
    },
  },
  {
    title: "Connect Four",
    image: "images/connectFour.png",
    description:
      "This Connect Four app was developed using Java and Android Studio. The game requires two players to play. A clean user interface was designed and multiple different animations were used",
    technologies: ["Java ", "Android Studio "],
    links: {
      projectLink: undefined,
      github: "https://github.com/zainafzal0/ConnectFour",
    },
  },
];
