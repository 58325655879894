export const workExperience = [
  {
    companyName: "Nokia",
    companyIcon: "images/NokiaLogo.png",
    jobPosition: "Software Developer",
    workPeriod: "Sep 2022 - Apr 2023",
    resumePoints: [
      "Established new repository featuring a model-driven info panel built with React, abstracting the foundational rendering architecture, empowering developers to manipulate the component exclusively through data models",
      "Implemented an advanced filter component using React and Javascript, streamlining frontend object model creation for developers, allowing high level of filter customization",
      "Spearheaded development and design of new chart components for resource manager app using React, JavaScript, HTML, and CSS increasing versatility in data visualization; demoed team wide and to stakeholders",
      "Refactored and designed new search filtering components improving responsiveness and efficiency by 35%",
    ],
  },
  {
    companyName: "Citylitics",
    companyIcon: "images/Citylitics.png",
    jobPosition: "Software Developer",
    workPeriod: "Jan - Apr 2022",
    resumePoints: [
      "Worked in an agile team to develop and enhance software solutions to deliver predictive intelligence on local utility and public infrastructure markets, analyzing related data across North America",
      "Utilized React, Redux, JavaScript, CSS and more to create various features and components on application used by intelligence and product teams to find targets more efficiently for customers and improve their workflow",
      "Implemented internal tools and refined REST API design using Django, Django Rest Framework, Python, and MySQL while also writing unit and integration tests using Pytest thereby increasing test coverage by 25%",
      "Wrote various scripts and incorporated features for web crawler pipeline using Python, Scrapy, Google Cloud Platform, and RabbitMQ increasing number of files retrieved per crawl by 300,000+ and decreasing cloud storage costs by 30%",
    ],
  },
  {
    companyName: "Schedulock",
    companyIcon: "images/Schedulock.png",
    jobPosition: "Software Developer",
    workPeriod: "May - Aug 2021",
    resumePoints: [
      "Collaborated in a team of developers to re-platform Schedulock’s real estate management software",
      "Designed and refactored core components/features such as sales reports, employee monitoring dashboards, and listing pages with React, JavaScript, CSS, Bootstrap, and ChartJS cutting down render times by 40%",
      "Constructed dynamic reports using complex queries to extract data and map various trends and statistics from MySQL database allowing advanced filtering capabilities",
      "Optimized backend REST API for high user traffic areas of the app with Java, Spring Boot, and AWS S3 leading to reduced calls and improving overall efficiency and performance by 53% on average",
    ],
  },
];
