import React, { useState } from "react";
import "./Experience.scss";

import { workExperience } from "../../generalObjects/Experience/workExperience";
import JobDescription from "./components/JobDescription";
import CompanyIcon from "./components/CompanyIcon";

import { Fade } from "react-awesome-reveal";

function Experience() {
  const [activeJob, setActiveJob] = useState(workExperience[0]);

  return (
    <div className="Experience_container" id="Work">
      <div className="uncenter_row section_heading">
        <h2 className="primary_title_text">Experience</h2>
        <hr className="line_seperator" />
      </div>
      <Fade duration={2500} triggerOnce={true}>
        <div className="work_experience_container">
          <div className="company_icons_container">
            {workExperience.map((job) => {
              return (
                <CompanyIcon
                  job={job}
                  active={activeJob.companyName}
                  setActiveJob={setActiveJob}
                />
              );
            })}
          </div>
          <JobDescription job={activeJob} />
        </div>
      </Fade>
    </div>
  );
}

export default Experience;
