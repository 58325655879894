import { Github, EnvelopeFill } from "react-bootstrap-icons";
import { RiLinkedinFill } from "react-icons/ri";

export const socialLinks = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/zainafzal0/",
    icon: <RiLinkedinFill />,
  },
  {
    name: "github",
    link: "https://github.com/zainafzal0",
    icon: <Github />,
  },
  {
    name: "email",
    link: "mailto:zain.afzal@uwaterloo.ca",
    icon: <EnvelopeFill />,
  },
];
