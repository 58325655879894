import React from "react";
import { Col } from "react-bootstrap";
import "./ProjectCard.scss";

function ProjectCard({ title, image, description, technologies }) {
  return (
    <Col className="ProjectCard_container">
      <div className=" round_border">
        <div className="project_image round_border">
          <img src={`${image}`} />
          <div className="project_descption">
            <h4 className="medium_text">{title}</h4>
            <p className="description">{description}</p>
            <p className="blue_text">
              {technologies.map((tech) => {
                return <span>{tech}</span>;
              })}
            </p>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default ProjectCard;
